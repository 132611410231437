import React, { FunctionComponent, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Project from '../../../../../../models/project/Project';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { returnObjectForAllCategoryListingRequest } from '../../../../../../store/action-creators/CategoriesActionCreators';
import CategoryModel from '../../../../../../models/v2/category/category.model';
import { articleAiGenerationSelectStylesVariant, GenerationErrorState } from '../../../helpers/article-ai-generation.helper';
import { categoriesToOptions } from '../../../../../Partials/Sidebar/general-content/helpers/category-select.helper';
import Select from 'react-select';
import { IScheduledContentGeneration } from '../../../models/models';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import '../../../styles/article-ai-generation-panel.scss';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';

type Properties = {
	project: Project;
	userId: string;
	getCategories: (page: string, project: Project, adminId: string) => void;
	scheduledGenerationContent: IScheduledContentGeneration;
	categoriesRedux: CategoryModel[];
	adminCategoriesRedux: CategoryModel[];
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
};

const CategorySelect: FunctionComponent<Properties> = ({
	project,
	userId,
	getCategories,
	errorMessage,
	errorState,
	setErrorState,
	scheduledGenerationContent,
	adminCategoriesRedux,
	categoriesRedux,
}) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();
	const hasErrorMessage = !!errorMessage && errorMessage.length > 0;

	useEffect(() => getCategories('1', project, userId), []);

	const onCategorySelect = (selectedCategoryOption: SelectMenuOptionType) => {
		const selectedCategory = (selectedCategoryOption && selectedCategoryOption.data) || null;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				category: selectedCategory,
			}),
		);

		setErrorState({
			...errorState,
			category: false,
		});
	};

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CATEGORY_SELECT}>
			<span className='input-fields-label'>{t('category')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CATEGORY_SELECT}
					value={
						scheduledGenerationContent &&
						scheduledGenerationContent.category &&
						categoriesToOptions([scheduledGenerationContent.category], adminCategoriesRedux)
					}
					options={categoriesToOptions(categoriesRedux, adminCategoriesRedux)}
					onChange={(selection) => onCategorySelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(hasErrorMessage, '232px')}
				/>
			}
			{errorMessage && (
				<div className='error-container'>
					<span className='error-icon' />
					<div className='error-message'> {errorMessage} </div>
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		userId: state.profile.profile.id || '',
		project: state.project.currentProject,
		categoriesRedux: state.category.allCategories || [], // all available categories
		adminCategoriesRedux: state.category.adminCategories || [], // categories only for the current user
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForAllCategoryListingRequest(page, project, adminId));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelect);
