import {
	initialScheduledState,
	IScheduledContentGeneration,
	ISchedulesAdvancedSearchFiltersModel,
} from '../../views/Pages/ArticleAiGeneration/models/models';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import {
	AI_ARTICLES_ADVANCED_FILTERS_UPDATE,
	AI_ARTICLES_LISTING_RECEIVED,
	AI_ARTICLES_PAGINATION_UPDATE,
	AI_SCHEDULED_GENERATION_CONTENT_UPDATE,
	AI_SCHEDULES_ADVANCED_FILTERS_UPDATE,
	AI_SCHEDULES_COUNT_UPDATE,
} from '../action-creators/ai-articles-action-creator';
import { PAGE_LIMIT } from '../../views/Pages/ArticleAiGeneration/helpers/listing.helper';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	scheduleListingFilters: ISchedulesAdvancedSearchFiltersModel;
	aiArticlesListingFilters: AdvancedFilterModel;
	aiArticlesPagination: PaginationMeta;
	schedulesCount: number;
	scheduledGenerationContent: IScheduledContentGeneration;
}

const initialState: InitialState = {
	scheduleListingFilters: {
		category_id: '',
		event_name: '',
		competition_id: '',
		created_by: '',
		to_date: '',
		from_date: '',
		status: '',
		page: 1,
		size: PAGE_LIMIT,
	} as ISchedulesAdvancedSearchFiltersModel,
	aiArticlesListingFilters: AdvancedFilterModel.builder().build(),
	aiArticlesPagination: PaginationMeta.builder().build(),
	schedulesCount: 0,
	scheduledGenerationContent: initialScheduledState,
};

function aiArticlesReducer(state: Record<string, any> = initialState, action: any) {
	if (action.type === AI_SCHEDULES_ADVANCED_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			scheduleListingFilters: {
				...action.payload.scheduleListingFilters,
				size: PAGE_LIMIT,
			},
		});
	}

	if (action.type === AI_ARTICLES_ADVANCED_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			aiArticlesListingFilters: action.payload.aiArticlesListingFilters,
		});
	}

	if (action.type === AI_ARTICLES_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			aiArticlesPagination: action.payload.aiArticlesPagination,
		});
	}

	if (action.type === AI_ARTICLES_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			aiArticlesPagination: action.payload.contentPagination,
		});
	}

	if (action.type === AI_SCHEDULED_GENERATION_CONTENT_UPDATE) {
		return Object.assign({}, state, {
			scheduledGenerationContent: action.payload.scheduledGenerationContent,
		});
	}

	if (action.type === AI_SCHEDULES_COUNT_UPDATE) {
		return Object.assign({}, state, {
			schedulesCount: action.payload.count,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default aiArticlesReducer;
