import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/template.styles.scss';
import { IContentTemplate, TemplateSectionType } from '../../../models/models';
import { DATA_QA } from '../../../helpers/data-qa.properties';

interface Props {
	template: IContentTemplate | null;
}

export const TemplatePreview: FunctionComponent<Props> = ({ template }) => {
	const { t } = useTranslation();

	if (!template) return null;

	const sections =
		template.prompt_template &&
		template.prompt_template.parameters &&
		template.prompt_template.parameters.structure &&
		template.prompt_template.parameters.structure.sections
			? template.prompt_template.parameters.structure.sections
			: [];

	return (
		<div data-qa={DATA_QA.ARTICLE_AI_GENERATION_ARTICLE_GENERATION_TEMPLATE_PREVIEW} className='template-preview-container'>
			<div className='template-preview-header'>
				<h1>{t('template_preview')}</h1>
				<span className='template-type-label'>{template.name}</span>
			</div>
			<div className='template-content-body'>
				{sections.map((section, index) => {
					const isWidget = section.type === TemplateSectionType.WIDGET;
					return isWidget ? (
						<div key={index} className='template-widget-container'>
							<div className='widget-icon' />
							<span className='widget-title-label'>{section.name}</span>
						</div>
					) : (
						<div key={index} className='template-paragraph-container'>
							<div className='paragraph-icon-container'>
								<div className='paragraph-icon' />
							</div>
							<div className='paragraph-texts-container'>
								<span className='paragraph-title-label'>{section.name}</span>
								<span className='paragraph-description-label'>{section.description}</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
