import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduledContent, ISchedulesAdvancedSearchFiltersModel } from '../../../models/models';
import '../../../styles/article-ai-generation-panel.scss';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { ContentTypes } from '../../../../../../constants/content-types';
import { formatScheduledDate, returnSchedulesListingStatusIcon, SchedulesListingStatuses } from '../../../helpers/listing.helper';
import { getDeleteIconAsCssUrl } from '../../../../../../global-helpers/global-icons.helpers';
import { DeleteScheduleModal } from './schedule-delete-modal';
import { useDispatch } from 'react-redux';
import { fetchSchedulesCount, useDeleteSchedule } from '../../../helpers/article-ai-requests.helper';

type Properties = {
	schedules: ScheduledContent[];
	getSchedules: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	filters: ISchedulesAdvancedSearchFiltersModel;
};

const SchedulesListingTable: FunctionComponent<Properties> = ({ schedules, getSchedules, filters }) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();
	const [selectedRowForDelete, setSelectedRowForDelete] = useState<ScheduledContent>();
	const { mutate: deleteScheduleMutation } = useDeleteSchedule(dispatch, filters, fetchSchedulesCount, getSchedules);

	const deleteSchedule = () => {
		const id = (selectedRowForDelete && selectedRowForDelete.id) || '';
		if (!id) return;

		deleteScheduleMutation(id);
	};

	return (
		<>
			<table className='table table-striped content-list-table' data-qa={`table-${ContentTypes.CUSTOM_ENTITIES}`}>
				<thead>
					<tr className='article-generation-schedules-listing-table-header'>
						<th style={{ width: '40%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('scheduled_event')}
						</th>
						<th style={{ width: '15%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('template_name')}
						</th>
						<th style={{ width: '20%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('status')}
						</th>
						<th style={{ width: '20%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('created_by')}
						</th>
						<th style={{ width: '20%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('scheduled')}
						</th>
						<th style={{ width: '20%' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('category')}
						</th>
						<th style={{ width: '15%', textAlign: 'center' }} className='p-0 article-generation-schedules-listing-table-header-label'>
							{t('actions')}
						</th>
					</tr>
				</thead>
				<tbody>
					{schedules && schedules.length > 0 ? (
						schedules.map((schedule: ScheduledContent) => {
							return (
								<tr
									className='article-generation-schedules-listing-table-row'
									key={`schedule-${schedule.id}`}
									data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_ITEM}
								>
									<td style={{ width: '40%' }} className='align-middle p-0'>
										<div className='schedule-event-name-cell'>
											<span className='schedule-event-name'>{schedule.match_name}</span>
											<span className='schedule-competition-name'>{`${schedule.competition_name} - ${formatScheduledDate(schedule.match_date)}`}</span>
										</div>
									</td>
									<td style={{ width: '15%' }} className='table-data align-middle p-0'>
										<span className='schedule-row-label'>{schedule.template_name}</span>
									</td>
									<td style={{ width: '20%' }} className='table-data align-middle p-0'>
										<div className='status-container'>
											<span
												className='status-icon'
												style={{
													backgroundImage: `url("${returnSchedulesListingStatusIcon(schedule.status as SchedulesListingStatuses)}")`,
												}}
											/>
											<span className='schedule-row-label'>{t(schedule.status ? schedule.status.toLowerCase() : '')}</span>
										</div>
									</td>
									<td style={{ width: '20%' }} className='table-data align-middle p-0'>
										<span className='schedule-row-label'>{schedule.created_by}</span>
									</td>
									<td style={{ width: '20%' }} className='table-data align-middle p-0'>
										<span className='schedule-row-label'>{`${formatScheduledDate(schedule.generation_time)}`}</span>
									</td>
									<td style={{ width: '20%' }} className='table-data align-middle p-0'>
										<span className='schedule-row-label'>{schedule.category_name}</span>
									</td>
									<td style={{ width: '15%' }} className='table-actions-container align-middle p-0'>
										<div
											data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_DELETE_BUTTON}
											className='delete-icon-container'
											style={{ backgroundImage: getDeleteIconAsCssUrl() }}
											onClick={() => setSelectedRowForDelete(schedule)}
										/>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<h6 className='d-flex justify-content-center mt-6 mb-4'>
								<span className='header-text mt-4 pad2x pad2y'>{t('no_data_found')}</span>
							</h6>
						</tr>
					)}
				</tbody>
			</table>
			<DeleteScheduleModal
				selectedRowForDelete={selectedRowForDelete}
				setSelectedRowForDelete={setSelectedRowForDelete}
				deleteContent={deleteSchedule}
			/>
		</>
	);
};

export default SchedulesListingTable;
