import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { articleAiGenerationSelectStylesVariant, GenerationErrorState } from '../../../helpers/article-ai-generation.helper';
import Select from 'react-select';
import { IContentTemplate, IScheduledContentGeneration } from '../../../models/models';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import '../../../styles/article-ai-generation-panel.scss';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';
import { templatesToOptions } from './helpers/template-select.helper';
import { useTemplates } from '../../../helpers/article-ai-requests.helper';

type Properties = {
	scheduledGenerationContent: IScheduledContentGeneration;
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
};

export const TemplateSelect: FunctionComponent<Properties> = ({ errorMessage, errorState, setErrorState, scheduledGenerationContent }) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();
	const [templateOptions, setTemplateOptions] = useState<IContentTemplate[] | null>(null);
	const hasErrorMessage = !!errorMessage && errorMessage.length > 0;
	const { data: templateData } = useTemplates();

	useEffect(() => {
		if (templateData) {
			setTemplateOptions(templateData);
		}
	}, [templateData]);

	const onTemplateSelect = (selectedTemplateOption: SelectMenuOptionType) => {
		const selectedTemplate = (selectedTemplateOption && selectedTemplateOption.data) || null;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				template: selectedTemplate,
				template_name: selectedTemplateOption.data.name,
				template_type: selectedTemplateOption.data.template_type,
			}),
		);

		setErrorState({
			...errorState,
			template: false,
		});
	};

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEMPLATE_SELECT}>
			<span className='input-fields-label'>{t('template_name')}</span>
			{
				<Select
					id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEMPLATE_SELECT}
					value={scheduledGenerationContent && scheduledGenerationContent.template && templatesToOptions([scheduledGenerationContent.template])}
					options={templatesToOptions(templateOptions ? templateOptions : [])}
					onChange={(selection) => onTemplateSelect(selection as SelectMenuOptionType)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					// @ts-ignore because of styles
					styles={articleAiGenerationSelectStylesVariant(hasErrorMessage, '232px')}
				/>
			}
			{errorMessage && (
				<div className='error-container'>
					<span className='error-icon' />
					<div className='error-message'> {errorMessage} </div>
				</div>
			)}
		</div>
	);
};
