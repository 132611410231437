import React, { FunctionComponent, useEffect, useState } from 'react';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { IScheduledContentGeneration } from '../../../models/models';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import '../../../styles/article-ai-generation-panel.scss';
import SportsConnectionsHttpService from '../../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { sportEventToOptions } from '../../../../../Partials/Sidebar/tags-refactored/helpers/sports-connection-select.helper';
import {
	articleAiGenerationSelectStylesVariant,
	constructEventRequestUrl,
	GenerationErrorState,
	SportEventCustomOption,
	SportSelectedEventCustomOption,
} from '../../../helpers/article-ai-generation.helper';
import { remapEventsResponse } from '../../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';
import { store } from '../../../../../../store/store';
import { OptionTypeData } from '../../../../../../constants/select';

type Properties = {
	scheduledGenerationContent: IScheduledContentGeneration;
	errorState: GenerationErrorState;
	errorMessage: string | undefined;
	setErrorState: (errorState: GenerationErrorState) => void;
	sport: string;
};

const EventSelect: FunctionComponent<Properties> = ({ scheduledGenerationContent, errorState, errorMessage, setErrorState, sport }) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();
	const [eventOptions, setEventOptions] = useState<OptionTypeData[] | null>(null);
	const hasErrorMessage = !!errorMessage && errorMessage.length > 0;

	const onEventSelect = (selectedEventOption: SelectMenuOptionType) => {
		const event = (selectedEventOption && selectedEventOption.data) || null;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				matches: [event],
			}),
		);

		setErrorState({
			...errorState,
			matches: false,
		});
	};

	const loadEventsData = () => {
		if (
			scheduledGenerationContent &&
			scheduledGenerationContent.team &&
			scheduledGenerationContent.team.id &&
			scheduledGenerationContent.team.id.length > 0
		) {
			const teamId = scheduledGenerationContent.team && scheduledGenerationContent.team.id;
			const language = store.getState().project.currentProject.language;

			const url = constructEventRequestUrl(teamId, sport, 100, language);

			SportsConnectionsHttpService.getGamesData(url, language)
				.then((response: any) => {
					const fetchedSportsData: Array<any> = response.data.results;
					const events = remapEventsResponse(fetchedSportsData);
					const loadedSportsConnectionsAsOptions = sportEventToOptions(events);
					setEventOptions(loadedSportsConnectionsAsOptions);
				})
				.catch((error) => {
					console.error('Error fetching events:', error);
					setEventOptions([]);
				});
		}
	};

	useEffect(() => {
		if (scheduledGenerationContent.team && scheduledGenerationContent.team.id) {
			loadEventsData();
		}
	}, [scheduledGenerationContent.team && scheduledGenerationContent.team.id]);

	return (
		<>
			<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_EVENT_SELECT}>
				<span className='input-fields-label'>{t('list_of_upcoming_events')}</span>
				{
					// @ts-ignore because of styles
					<Select
						id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_EVENT_SELECT}
						className='custom-event-select'
						value={sportEventToOptions(scheduledGenerationContent.matches && scheduledGenerationContent.matches)}
						onChange={(selection) => onEventSelect(selection as SelectMenuOptionType)}
						placeholder={t('select_event')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						options={eventOptions ? eventOptions : []}
						formatOptionLabel={SportSelectedEventCustomOption}
						components={{
							Option: SportEventCustomOption,
						}}
						styles={articleAiGenerationSelectStylesVariant(hasErrorMessage, '480px', false)}
					/>
				}
				{errorMessage && (
					<div className='error-container'>
						<span className='error-icon' />
						<div className='error-message'> {errorMessage} </div>
					</div>
				)}
			</div>
		</>
	);
};

export default EventSelect;
