export enum DATA_QA {
	ARTICLE_AI_GENERATION_WRAPPER = 'article-ai-generation-wrapper',
	ARTICLE_AI_SCHEDULES_LISTING_WRAPPER = 'article-ai-schedules-listing-wrapper',
	ARTICLE_AI_CREATED_ARTICLES_LISTING_WRAPPER = 'article-ai-created-articles-listing-wrapper',
	ARTICLE_AI_GENERATION_TABS_MENU = 'article-ai-generation-tabs-menu',
	ARTICLE_AI_GENERATION_ARTICLE_GENERATION_SCREEN = 'article-ai-generation-article-generation-screen',
	ARTICLE_AI_GENERATION_ARTICLE_GENERATION_TEMPLATE_PREVIEW = 'article-ai-generation-article-generation-template-preview',
	ARTICLE_AI_GENERATION_SCHEDULES_LISTING_SCREEN = 'article-ai-generation-article-schedules-listing-screen',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CATEGORY_SELECT = 'article-ai-generation-article-generation-category-select',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_STRAPLINE = 'article-ai-generation-article-generation-generate-strapline',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_SUMMARY = 'article-ai-generation-article-generation-generate-summary',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_GENERATE_BASED_ON = 'article-ai-generation-article-generation-generate-based-on',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEAM_SELECT = 'article-ai-generation-article-generation-team-select',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_EVENT_SELECT = 'article-ai-generation-article-generation-event-select',
	ARTICLE_AI_GENERATION_INFO_PANEL = 'article-ai-generation-article-info-panel',
	ARTICLE_AI_GENERATION_DATE_AND_TIME_SELECT = 'article-ai-generation-date-and-time-select',
	ARTICLE_AI_GENERATION_COMPETITION_FILTER_TOGGLE = 'article-ai-generation-competition-filter-toggle',
	ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS = 'article-ai-generation-competition-filter-events',
	ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS_COMPETITION_GROUP = 'article-ai-generation-competition-filter-events-competition-group',
	ARTICLE_AI_GENERATION_COMPETITION_FILTER_EVENTS_EVENT = 'article-ai-generation-competition-filter-events-event',
	ARTICLE_AI_SCHEDULES_LISTING_SEARCH_BAR = 'article-ai-schedules-listing-search-bar',
	ARTICLE_AI_SCHEDULES_LISTING_ADVANCED_FILERS_TOGGLE = 'article-ai-schedules-listing-advanced-filters-toggle',
	ARTICLE_AI_SCHEDULES_LISTING_TABLE = 'article-ai-schedules-listing-table',
	ARTICLE_AI_SCHEDULES_LISTING_PAGINATION = 'article-ai-schedules-listing-pagination',
	ARTICLE_AI_SCHEDULES_LISTING_DELETE_BUTTON = 'article-ai-schedules-listing-delete-button',
	ARTICLE_AI_SCHEDULES_LISTING_ITEM = 'article-ai-schedules-listing-item',
	ARTICLE_AI_SCHEDULES_SCHEDULE_DELETE_MODAL = 'article-ai-schedules-schedule-delete-modal',
	ARTICLE_AI_SCHEDULES_SCHEDULE_ADVANCED_SEARCH_FILTERS = 'article-ai-schedules-schedule-advanced-search-filters',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_CREATED_BY_SELECT = 'article-ai-generation-article-generation-created-by-select',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_STATUS_SELECT = 'article-ai-generation-article-generation-status-select',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_COMPETITION_SELECT = 'article-ai-generation-article-generation-competition-select',
	ARTICLE_AI_GENERATION_DATE_AND_TIME_FROM_SELECT = 'article-ai-generation-date-and-time-from-select',
	ARTICLE_AI_GENERATION_DATE_AND_TIME_TO_SELECT = 'article-ai-generation-date-and-time-to-select',
	ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEMPLATE_SELECT = 'article-ai-generation-article-generation-template-select',
}
