import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	IScheduledContentGenerationResponse,
	IScheduledContentPaginationResponse,
	ScheduledContent,
	ISchedulesAdvancedSearchFiltersModel,
} from '../../models/models';
import { DATA_QA } from '../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import '../../styles/article-ai-generation-panel.scss';
import { AppState } from '../../../../../store/store';
import ArticleSchedulerHttpService from '../../helpers/article-scheduler-http-service';
import SchedulesListingSearch from '../subcomponents/schedules-listing-components/schedules-listing-search';
import { updateAiSchedulesAdvancedSearchFilters } from '../../../../../store/action-creators/ai-articles-action-creator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SchedulesListingTable from '../subcomponents/schedules-listing-components/schedules-listing-table';
import ReactPaginate from 'react-paginate';
import { extractScheduledArticlesListingSearchQueryFromFilters, schedulesListingPaginateProps } from '../../helpers/listing.helper';
import { ScheduledArticlesAdvancedFilters } from '../subcomponents/schedules-listing-components/advanced-filters';
import { showCustomToast } from '../subcomponents/schedules-listing-components/subcomponents/custom-toast-norification';
import { GenerateArticleRequestStatuses } from '../../helpers/article-ai-generation.helper';
import Project from '../../../../../models/project/Project';

interface Props {
	schedulesAdvancedSearchFilters: ISchedulesAdvancedSearchFiltersModel;
	schedulesAdvancedSearchFiltersUpdate: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	project: Project;
}

const SchedulesListingScreen: FunctionComponent<Props> = ({
	schedulesAdvancedSearchFilters,
	schedulesAdvancedSearchFiltersUpdate,
	project,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [schedules, setSchedules] = useState<ScheduledContent[]>();
	const [filters, setFilters] = useState<ISchedulesAdvancedSearchFiltersModel>({ ...schedulesAdvancedSearchFilters });
	const searchQueryFromFilters = extractScheduledArticlesListingSearchQueryFromFilters(filters);
	const [paginationData, setPaginationData] = useState<IScheduledContentPaginationResponse>();
	const [toggleFilters, setToggleAdvancedFilters] = useState(!!(searchQueryFromFilters && searchQueryFromFilters.length > 0));

	useEffect(() => {
		getSchedules(filters);
	}, []);

	const getSchedules = (filters: ISchedulesAdvancedSearchFiltersModel) => {
		try {
			setIsLoading(true);
			ArticleSchedulerHttpService.getScheduleListing(filters, project.timezone)
				.then((response) => {
					const responseData = response.data as IScheduledContentGenerationResponse;
					const data = responseData && (responseData.content as ScheduledContent[]);
					const paginationData =
						responseData &&
						({
							total_pages: responseData.total_pages,
							total_elements: responseData.total_elements,
							page: responseData.page,
							actual_size: responseData.actual_size,
						} as IScheduledContentPaginationResponse);

					setSchedules(data);
					setPaginationData(paginationData);
					setIsLoading(false);
				})
				.catch((error) => {
					const errorMessage = error.response.data.message || error.message || t('error_retrieving_schedules');
					showCustomToast(t('error_occurred'), errorMessage, GenerateArticleRequestStatuses.ERROR);
					setIsLoading(false);
				});
		} catch (error) {
			showCustomToast(t('error_occurred'), t('error_retrieving_schedules'), GenerateArticleRequestStatuses.ERROR);
		}
	};

	const onPageClick = (data: Record<string, number>) => {
		const selectedPage = data.selected + 1;
		setFilters({ ...filters, page: selectedPage });
		schedulesAdvancedSearchFiltersUpdate({ ...filters, page: selectedPage });
		getSchedules({ ...filters, page: selectedPage });
	};

	const updateSearchQuery = (filters: ISchedulesAdvancedSearchFiltersModel) => {
		setFilters(filters);
		schedulesAdvancedSearchFiltersUpdate(filters);
	};

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
	};

	return (
		<div
			data-qa={DATA_QA.ARTICLE_AI_GENERATION_SCHEDULES_LISTING_SCREEN}
			className={`${isLoading ? 'loading-overlay' : ''} schedules-listing-container`}
		>
			<div className='article-generation-schedules-listing-header'>
				<div className='title-section'>
					<h1>{t('scheduled_articles')}</h1>
					<span className='header-description-label'>{t('schedules_listing_description')}</span>
				</div>
				<SchedulesListingSearch
					getContent={getSchedules}
					filters={schedulesAdvancedSearchFilters}
					setFilters={updateSearchQuery}
					setToggle={toggleAdvancedFilters}
				/>
			</div>
			<div className='advanced-filters-container'>
				{toggleFilters && (
					<ScheduledArticlesAdvancedFilters
						contentSearchInput={filters.event_name}
						updateSchedulesAdvancedSearchText={updateSearchQuery}
						getContent={getSchedules}
					/>
				)}
			</div>
			<div data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_TABLE} className='article-generation-schedules-listing-table-container'>
				<SchedulesListingTable schedules={schedules ? schedules : []} getSchedules={getSchedules} filters={filters} />
			</div>
			{paginationData && (
				<div className='pagination-container' data-qa={DATA_QA.ARTICLE_AI_SCHEDULES_LISTING_PAGINATION}>
					<ReactPaginate {...schedulesListingPaginateProps(paginationData, onPageClick)} />
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state: AppState) {
	return {
		schedulesAdvancedSearchFilters: state.aiArticles.scheduleListingFilters,
		project: state.project.currentProject,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		schedulesAdvancedSearchFiltersUpdate: (filters: ISchedulesAdvancedSearchFiltersModel) =>
			dispatch(updateAiSchedulesAdvancedSearchFilters(filters)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SchedulesListingScreen);
