import CategoryModel from '../../../../models/v2/category/category.model';
import { SportTypes } from '../../../../constants/sport.types';
import SportsEntityModel from '../../../../models/v2/sports-types/sports-entity.model';
import EventModel from '../../../../models/v2/sports-types/eventModel';
import { IEventsByDate } from '../../../Partials/Blocky/v2/types/livescore';
import { PAGE_LIMIT } from '../helpers/listing.helper';

export interface IScheduledContentGeneration {
	template_name: string | null;
	template_type: string | null;
	generate_summary: boolean | null;
	generate_strapline: boolean | null;
	category: CategoryModel | null;
	user_id: string | null;
	user_name: string | null;
	sport: string | null;
	matches: EventModel[];
	generate_based_on: string | null;
	team: SportsEntityModel | null;
	generation_date: string[] | null;
	competition_ids: string[] | null;
	competition_events: IEventsByDate[] | null;
	template: IContentTemplate | null;
}

export const initialScheduledState: IScheduledContentGeneration = {
	template_type: null,
	template_name: null,
	sport: SportTypes.FOOTBALL.toUpperCase(),
	generate_summary: null,
	generate_strapline: null,
	category: null,
	user_id: null,
	user_name: null,
	matches: [],
	generate_based_on: null,
	team: null,
	generation_date: null,
	competition_ids: null,
	competition_events: null,
	template: null,
};

export const initialSchedulesAdvancedFiltersState: ISchedulesAdvancedSearchFiltersModel = {
	created_by: '',
	to_date: '',
	from_date: '',
	competition_id: '',
	event_name: '',
	category_id: '',
	status: '',
	page: 1,
	size: PAGE_LIMIT,
};

export interface IScheduledContentGenerationResponse {
	content: ScheduledContent[] | null;
	page: number | null;
	actual_size: number | null;
	total_elements: number | null;
	total_pages: number | null;
}

export interface IScheduledContentPaginationResponse {
	page: number | null;
	actual_size: number | null;
	total_elements: number | null;
	total_pages: number | null;
}

export interface ISchedulesAdvancedSearchFiltersModel {
	created_by: string;
	to_date: string;
	from_date: string;
	competition_id: string;
	event_name: string;
	category_id: string;
	status: string;
	page: number;
	size: number;
}

export interface ScheduledContent {
	id: string | null;
	match_name: string | null;
	match_date: string | null;
	generation_time: string | null;
	article_id: string | null;
	created_by: string | null;
	competition_name: string | null;
	sport: string | null;
	message: string | null;
	category_name: string | null;
	template_name: string | null;
	status: string | null;
}

export interface IMatch {
	match_id: string;
	competition_id: string;
	competition_name: string;
	match_name: string;
	match_date: string;
}

export interface IScheduledContent {
	id: string;
	template_name: string;
	template_type: string;
	match_id: string;
	schedule_name: string;
	status: string;
	generation_time: string;
	article_id: string;
	created_at: string;
	updated_at: string;
	created_by: string;
}

export enum TemplateSectionType {
	TEXT_PARAGRAPH = 'TEXT_PARAGRAPH',
	WIDGET = 'WIDGET',
}

export interface ITemplateStructureSection {
	name: string;
	description: string;
	text?: string;
	type: TemplateSectionType;
}

export interface ITemplateStructure {
	title: string;
	widgets: Record<any, string>[];
	sections: ITemplateStructureSection[];
}

export interface ITemplatePromptParameters {
	structure: ITemplateStructure;
}

export interface ITemplatePrompt {
	prompt: string;
	parameters: ITemplatePromptParameters;
}

export interface IContentTemplate {
	template_type: 'PRE_GAME' | string;
	name: string;
	description: string;
	prompt_template: ITemplatePrompt;
}
