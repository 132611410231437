import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import { IScheduledContentGeneration } from '../../../models/models';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import '../../../styles/article-ai-generation-panel.scss';
import AsyncSelect from 'react-select/async';
import { store } from '../../../../../../store/store';
import SportsConnectionsHttpService from '../../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import {
	remapSportsConnectionsResponseToConnectionsModel,
	sportConnectionsToOptions,
} from '../../../../../Partials/Sidebar/tags-refactored/helpers/sports-connection-select.helper';
import debounce from 'lodash.debounce';
import { generateCustomOptionWithImage } from '../../../../../Partials/Sidebar/tags-refactored/helpers/utils';
import { articleAiGenerationSelectStylesVariant, SportTeamCustomOption } from '../../../helpers/article-ai-generation.helper';
import { useDispatch } from 'react-redux';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';
import SportsEntityModel from '../../../../../../models/v2/sports-types/sports-entity.model';

type Properties = {
	scheduledGenerationContent: IScheduledContentGeneration;
	sport: string;
	entityTypes: string[];
};

const TeamSelect: FunctionComponent<Properties> = ({ scheduledGenerationContent, sport, entityTypes }) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();

	const onTeamSelect = (selectedTeamOption: SelectMenuOptionType) => {
		const team = (selectedTeamOption && selectedTeamOption.data) || null;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				team: team,
				matches: [],
			}),
		);
	};

	const loadTeamsData = (input: string, callback: any) => {
		if (input.length > 2) {
			const language = store.getState().project.currentProject.language;

			SportsConnectionsHttpService.getSportsData(input, sport, entityTypes, language).then((response: any) => {
				const fetchedSportsData: Array<SportsEntityModel> = response.data.results;
				const remappedSportsData = remapSportsConnectionsResponseToConnectionsModel(fetchedSportsData);
				const loadedSportsConnectionsAsOptions = sportConnectionsToOptions(remappedSportsData);
				callback(loadedSportsConnectionsAsOptions);
			});
		}
	};

	return (
		<>
			<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEAM_SELECT}>
				<span className='input-fields-label'>{t('team')}</span>
				{
					// @ts-ignore because of styles
					<AsyncSelect
						id={DATA_QA.ARTICLE_AI_GENERATION_GENERATE_ARTICLE_TEAM_SELECT}
						className='custom-team-select'
						styles={articleAiGenerationSelectStylesVariant(false, '232px')}
						value={sportConnectionsToOptions(scheduledGenerationContent.team && [scheduledGenerationContent.team])}
						onChange={(selection) => onTeamSelect(selection as SelectMenuOptionType)}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						loadOptions={debounce(loadTeamsData, 300)}
						formatOptionLabel={(option) =>
							generateCustomOptionWithImage(option.label, option.value, option.data.display_asset.url, option.data.entity_type)
						}
						components={{
							Option: SportTeamCustomOption,
						}}
					/>
				}
			</div>
		</>
	);
};

export default TeamSelect;
