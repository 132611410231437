import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
	articleAiGenerationSelectStylesVariant,
	GENERATE_TYPES_OPTIONS,
	SelectMenuOptionType,
} from '../../../helpers/article-ai-generation.helper';
import Select from 'react-select';
import { IScheduledContentGeneration } from '../../../models/models';
import '../../../styles/article-ai-generation-panel.scss';
import { useDispatch } from 'react-redux';
import { updateScheduledGenerationContent } from '../../../../../../store/action-creators/ai-articles-action-creator';

type Properties = {
	options: Record<string, any>;
	scheduledGenerationContent: IScheduledContentGeneration;
	labelText: string;
	dataQa: string;
	type: string;
	isOptional?: boolean;
};

const GenericGenerateField: FunctionComponent<Properties> = ({
	dataQa,
	labelText,
	options,
	scheduledGenerationContent,
	type,
	isOptional,
}) => {
	const [t] = useTranslation();
	const dispatch = useDispatch();

	const onValueSelect = (selectedOption: SelectMenuOptionType) => {
		const selected = selectedOption && selectedOption.value;

		dispatch(
			updateScheduledGenerationContent({
				...scheduledGenerationContent,
				[`generate_${type}`]: selected,
			}),
		);
	};

	return (
		<div className='input-fields-group' data-qa={dataQa}>
			<div className='generic-field-labels-container'>
				<span className='input-fields-label'>{t(labelText)}</span>
				{isOptional && <span className='optional-label'>{`(${t(`optional`)})`}</span>}
			</div>
			{
				// @ts-ignore because of styles
				<Select
					id={dataQa}
					value={
						scheduledGenerationContent[`generate_${type}`] !== undefined
							? GENERATE_TYPES_OPTIONS(t).find((option) => option.value === scheduledGenerationContent[`generate_${type}`])
							: null
					}
					options={options}
					onChange={(selection) => onValueSelect(selection as SelectMenuOptionType)}
					placeholder={t('yes_no')}
					styles={articleAiGenerationSelectStylesVariant(false, '232px', false)}
				/>
			}
		</div>
	);
};

export default GenericGenerateField;
