import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { IContentTemplate } from '../../../../models/models';

export const templatesToOptions = (templates: IContentTemplate[]): SelectMenuOptionType[] => {
	return templates && templates.length > 0 ? templates.map((template: IContentTemplate) => returnMappedTemplate(template)) : [];
};

export const returnMappedTemplate = (template: IContentTemplate) => {
	return {
		value: template.name,
		label: template.name,
		data: template,
	};
};
