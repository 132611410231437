import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';
import { store } from '../../../../store/store';
import axios from 'axios';
import { IScheduledContentGeneration } from '../models/models';
import i18next from 'i18next';

const baseURL = REACT_APP_URLS.REACT_APP_ARTICLE_SCHEDULER_API_BASE_URL;
const SCHEDULER_API_AUTH = REACT_APP_URLS.REACT_APP_GET_ARTICLE_SCHEDULER_API_AUTH();

export default class ArticleSchedulerHttpService {
	static getGeneralInstanceHeaders = () => {
		const reduxState = store.getState();

		return {
			'X-Project': `${reduxState.project.currentProject.domain}`,
			'Content-Type': 'application/json',
			Authorization: SCHEDULER_API_AUTH,
		};
	};

	static instance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...ArticleSchedulerHttpService.getGeneralInstanceHeaders(),
			},
		});
	};

	static generateSchedule = async (data: IScheduledContentGeneration) => {
		try {
			const response = await ArticleSchedulerHttpService.instance().post(`/schedules`, data);

			if (response && response.status === 201) {
				let hasWarning = false;
				let hasSuccess = false;

				response.data.forEach((item: any) => {
					if (item.message && item.message.includes('already exists')) {
						hasWarning = true;
					} else {
						hasSuccess = true;
					}
				});

				return { data: response.data, hasSuccess, hasWarning };
			}
			return { data: [], hasSuccess: false, hasWarning: false };
		} catch (error) {
			const errorMessage = (error.response && error.response.data && error.response.data.message) || i18next.t('error_generating_schedule');
			throw new Error(errorMessage);
		}
	};

	static getSchedule = (id: string) => {
		return ArticleSchedulerHttpService.instance().get(`/schedules/${id}`);
	};

	static deleteSchedule = (id: string) => {
		return ArticleSchedulerHttpService.instance().delete(`/schedules/${id}`);
	};

	static getSchedulesCount = () => {
		return ArticleSchedulerHttpService.instance().get(`/schedules/count`);
	};

	static getScheduleListing = (
		filters: {
			category_id?: string;
			event_name?: string;
			competition_id?: string;
			to_date?: string;
			from_date?: string;
			created_by?: string;
			page?: number;
			size?: number;
			status?: string;
		} = {},
		timeZone?: string,
	) => {
		const queryParams = Object.entries(filters)
			.filter(([_, value]) => value !== undefined && value !== null && value !== '')
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

		if (filters.to_date && timeZone) {
			queryParams.push(`time_zone=${encodeURIComponent(timeZone)}`);
		}

		const url = `/schedules${queryParams.length ? `?${queryParams.join('&')}` : ''}`;

		return ArticleSchedulerHttpService.instance().get(url);
	};

	static getTemplates = () => {
		return ArticleSchedulerHttpService.instance().get(`/templates`);
	};
}
