import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../styles/article-ai-generation-panel.scss';
import '../../../../styles/date-picker.styles.scss';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { AppState } from '../../../../../../../store/store';
import { DATA_QA } from '../../../../helpers/data-qa.properties';
import { dateFormat, langs } from '../../../../../../Partials/Sidebar/general-content/constants/date-time.attributes';
import { ISchedulesAdvancedSearchFiltersModel } from '../../../../models/models';
import { getLocalEquivalentOfMidnightInProjectTimezone } from '../../../../helpers/article-ai-generation.helper';

type Properties = {
	schedulesFilters: ISchedulesAdvancedSearchFiltersModel;
	updateSchedulesAdvancedSearchText: (filters: ISchedulesAdvancedSearchFiltersModel) => void;
	field: 'from_date' | 'to_date';
};

const ScheduledListingGenerationTimeAndDateSelect: FunctionComponent<Properties> = ({
	schedulesFilters,
	updateSchedulesAdvancedSearchText,
	field,
}) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const language = useSelector((state: AppState) => state.profile.profile.language);
	const [selectedDate, setSelectedDate] = useState<string>(schedulesFilters[field] || '');
	const flatpickrRef = useRef<any>(null);

	useEffect(() => {
		setSelectedDate(schedulesFilters[field] || '');
	}, [schedulesFilters[field]]);

	const formatDateToISO = (date: Date | string) => {
		const d = new Date(date);
		return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T${String(
			d.getHours(),
		).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}:00.000`;
	};

	const label = field === 'from_date' ? t('generation_date_from') : t('generation_date_to');

	const fromDate = schedulesFilters.from_date;
	const toDate = schedulesFilters.to_date;

	const minDate = field === 'to_date' && fromDate ? new Date(fromDate) : getLocalEquivalentOfMidnightInProjectTimezone(project.timezone);

	const maxDate = field === 'from_date' && toDate ? new Date(toDate) : undefined;

	useEffect(() => {
		const storedValue = schedulesFilters[field];
		setSelectedDate(storedValue || '');

		if (storedValue && flatpickrRef.current) {
			const parsedDate = new Date(storedValue);
			flatpickrRef.current.flatpickr.setDate(parsedDate, false);
		}
	}, [schedulesFilters[field]]);

	return (
		<div className='input-fields-group' data-qa={DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_SELECT}>
			<div className='datepicker-label-container'>
				<span className='input-fields-label'>{label}</span>
			</div>
			<div className='datepicker-container'>
				<div className='datepicker-wrapper'>
					<Flatpickr
						id={`${DATA_QA.ARTICLE_AI_GENERATION_DATE_AND_TIME_SELECT}-${field}`}
						className='article-generation-advanced-search-date-picker'
						disabled={field === 'to_date' && !schedulesFilters.from_date}
						options={{
							defaultDate: selectedDate || '',
							dateFormat: dateFormat,
							mode: 'single',
							time_24hr: true,
							enableTime: true,
							locale: langs[language],
							minDate: minDate,
							maxDate: maxDate,
							onChange: (selectedDates) => {
								if (selectedDates.length === 1) {
									const selected = selectedDates[0];
									const newDate = formatDateToISO(selected);
									if (newDate !== selectedDate) {
										const updatedModel = {
											...schedulesFilters,
											[field]: newDate,
										};
										updateSchedulesAdvancedSearchText(updatedModel);
										setSelectedDate(newDate);

										if (flatpickrRef.current) {
											flatpickrRef.current.flatpickr.setDate(selected, false);
										}
									}
								}
							},
						}}
						value={selectedDate}
						placeholder={t('select_date')}
						ref={flatpickrRef}
					/>
					<span className='calendar-icon' />
				</div>
			</div>
		</div>
	);
};

export default ScheduledListingGenerationTimeAndDateSelect;
